import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent  {

  contactForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(60)]],
      lastName: ['', [Validators.required, Validators.maxLength(60)]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: [''],
      message: ['', [Validators.required, Validators.maxLength(250)]]
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      alert('Message sent');
      this.contactForm.reset();
    } else {
      Object.values(this.contactForm.controls).forEach(control => {
        control.markAsTouched();
      });
      return; // Exit if form is invalid
    }
  }

}
