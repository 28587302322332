import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  activeMenu: string | null = null;
  accordionState: { [key: string]: boolean } = {};
  constructor() { }

  ngOnInit() {
  }

  showSubMenu(menu: string) {
    this.activeMenu = menu;
  }

  hideSubMenu() {
    this.activeMenu = null;
  }

  toggleAccordion(section: string) {
    this.accordionState[section] = !this.accordionState[section];
  }

  isAccordionOpen(section: string): boolean {
    return !!this.accordionState[section];
  }

  isMenuOpen = false;

  toggleMobileMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
